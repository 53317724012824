import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  // Divider,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
} from "@mui/material";
// import GoogleIcon from "@mui/icons-material/Google";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import AppleIcon from "@mui/icons-material/Apple";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import { useNavigate } from "react-router-dom";

import "../styles/auth.css";

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegister = async () => {
    if (!termsAccepted) {
      setError(
        "Vui lòng đồng ý với điều khoản dịch vụ và chính sách quyền riêng tư."
      );
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError("Mật khẩu xác nhận không khớp. Vui lòng thử lại.");
      return;
    }

    try {
      await apiClient.post(endpoints.users.register, {
        name: formData.name,
        email: formData.email,
        password: formData.password,
      });

      setSuccess(true);
      setTimeout(() => navigate("/login"), 2000); // Chuyển đến trang đăng nhập
    } catch (err) {
      console.error("Lỗi đăng ký:", err);
      // Kiểm tra lỗi email đã tồn tại

      if (err.response?.status === 409) {
        setError("Email này đã được sử dụng. Vui lòng thử email khác.");
      } else {
        setError(
          err.response?.data?.message ||
            "Đã xảy ra lỗi trong quá trình đăng ký. Vui lòng thử lại sau."
        );
      }
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "400px",
        margin: "50px auto",
        padding: "20px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: "20px" }}>
        Đăng ký tài khoản
      </Typography>
      <TextField
        fullWidth
        label="Tên của bạn"
        name="name"
        margin="normal"
        value={formData.name}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        label="Địa chỉ Email"
        name="email"
        margin="normal"
        value={formData.email}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        label="Mật khẩu"
        name="password"
        type="password"
        margin="normal"
        value={formData.password}
        onChange={handleInputChange}
      />
      <TextField
        fullWidth
        label="Xác nhận mật khẩu"
        name="confirmPassword"
        type="password"
        margin="normal"
        value={formData.confirmPassword}
        onChange={handleInputChange}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
        }
        label="Tôi đồng ý với điều khoản dịch vụ và chính sách quyền riêng tư."
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{ marginTop: "20px" }}
        onClick={handleRegister}
      >
        Đăng ký
      </Button>
      <br />
      <br />
      {/* <Divider>Hoặc</Divider>
      <Box sx={{ marginTop: "20px" }}>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<GoogleIcon />}
          sx={{ marginBottom: "10px" }}
        >
          Đăng nhập bằng Google
        </Button>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<FacebookIcon />}
          sx={{ marginBottom: "10px" }}
        >
          Đăng nhập bằng Facebook
        </Button>
        <Button fullWidth variant="outlined" startIcon={<AppleIcon />}>
          Đăng nhập bằng Apple
        </Button>
      </Box> */}

      {/* Snackbar thông báo lỗi */}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={4000}
          onClose={() => setError(null)}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      )}

      {/* Snackbar thông báo thành công */}
      {success && (
        <Snackbar
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert severity="success" onClose={() => setSuccess(false)}>
            Đăng ký thành công! Đang chuyển hướng đến trang đăng nhập...
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default RegisterPage;
