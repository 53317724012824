import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Avatar,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import "../styles/Menu.css";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";

import HeaderLogo from "../components/HeaderLogo";

const DesktopMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(null); // Thông tin người dùng
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { id: 1, label: "Trang chủ", link: "/home" },
    { id: 2, label: "Tin nhanh", link: "/news" },
    { id: 3, label: "Mẹo vặt", link: "/tips-and-tricks" },
    { id: 4, label: "Chuyên Mục", link: "/categories" },
  ];

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    setUser(null);
    setAnchorEl(null); // Đóng menu
    navigate("/home"); // Chuyển hướng về trang chủ
  };

  // Gọi API để lấy thông tin người dùng
  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("access_token");
      if (token) {
        try {
          const response = await apiClient.get(endpoints.users.profile, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUser(response.data);
        } catch (error) {
          console.error("Lỗi khi lấy thông tin người dùng:", error);
          localStorage.removeItem("access_token");
          navigate("/home");
        }
      }
    };

    fetchUserProfile();
  }, [navigate]);

  return (
    <>
      <Box className="menu-container">
        {/* Logo */}
        <Box className="menu-logo-container">
          <Link to="/home" className="menu-logo-link">
            <HeaderLogo />
          </Link>
        </Box>

        {/* Menu Items */}
        <Box className="menu-items-container">
          {menuItems.map((item) => (
            <Link
              key={item.id}
              to={item.link}
              className={`menu-item ${
                location.pathname === item.link ? "menu-item-active" : ""
              }`}
            >
              {item.label}
            </Link>
          ))}
        </Box>

        {/* User Account Section */}
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handleMenuClick}
            sx={{
              color: "#15293f",
              padding: 1,
              "&:hover": {
                backgroundColor: "#e7e7e3",
              },
            }}
          >
            {user ? (
              <Avatar alt={user.name} src={user.avatar} />
            ) : (
              <AccountCircle sx={{ fontSize: 32 }} />
            )}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{ mt: 1 }}
          >
            {user
              ? [
                  <MenuItem key="profile" onClick={handleMenuClose}>
                    <Link to={`/profile`} className="dropdown-link">
                      <Typography>Trang cá nhân</Typography>
                    </Link>
                  </MenuItem>,
                  <MenuItem key="info-profile" onClick={handleMenuClose}>
                    <Link
                      to="/account/personal-details"
                      className="dropdown-link"
                    >
                      <Typography>Thông tin cá nhân</Typography>
                    </Link>
                  </MenuItem>,
                  <MenuItem key="logout" onClick={handleLogout}>
                    <Typography>Đăng xuất</Typography>
                  </MenuItem>,
                ]
              : [
                  <MenuItem key="login" onClick={handleMenuClose}>
                    <Link to="/login" className="dropdown-link">
                      Đăng nhập tài khoản
                    </Link>
                  </MenuItem>,
                  <MenuItem key="register" onClick={handleMenuClose}>
                    <Link to="/register" className="dropdown-link">
                      Đăng ký tài khoản mới
                    </Link>
                  </MenuItem>,
                ]}
          </Menu>
        </Box>
      </Box>
    </>
  );
};

export default DesktopMenu;
