import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  // Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
// import GoogleIcon from "@mui/icons-material/Google";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import AppleIcon from "@mui/icons-material/Apple";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";

import "../styles/auth.css";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null); // Error state
  const [success, setSuccess] = useState(false); // Success state

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await apiClient.post(endpoints.auth.login, {
        email,
        password,
      });

      const { access_token } = response.data;
      localStorage.setItem("access_token", access_token);

      setSuccess(true);

      setTimeout(() => navigate("/home"), 2000);
    } catch (err) {
      setError(
        "Đăng nhập thất bại. Vui lòng kiểm tra lại tài khoản hoặc mật khẩu."
      );
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "400px",
        margin: "50px auto",
        padding: "20px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: "20px" }}>
        Đăng nhập tài khoản
      </Typography>
      <TextField
        fullWidth
        label="Tên tài khoản hoặc Email"
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        fullWidth
        label="Mật khẩu"
        type="password"
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Box sx={{ textAlign: "right", marginBottom: "20px" }}>
        <Typography variant="body2" color="primary">
          Quên mật khẩu?
        </Typography>
      </Box>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{ marginBottom: "20px" }}
        onClick={handleLogin}
      >
        Đăng nhập
      </Button>
      <br />
      {/* <Divider>Hoặc</Divider>
      <Box sx={{ marginTop: "20px" }}>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<GoogleIcon />}
          sx={{ marginBottom: "10px" }}
        >
          Đăng nhập bằng Google
        </Button>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<FacebookIcon />}
          sx={{ marginBottom: "10px" }}
        >
          Đăng nhập bằng Facebook
        </Button>
        <Button fullWidth variant="outlined" startIcon={<AppleIcon />}>
          Đăng nhập bằng Apple
        </Button>
      </Box> */}
      <Box sx={{ marginTop: "20px" }}>
        <Typography variant="body2">
          Chưa có tài khoản? <Link to="/register">Đăng ký</Link>
        </Typography>
      </Box>

      {/* Snackbar thông báo lỗi */}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={4000}
          onClose={() => setError(null)}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      )}

      {/* Snackbar thông báo thành công */}
      {success && (
        <Snackbar
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert severity="success" onClose={() => setSuccess(false)}>
            Đăng nhập thành công!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default LoginPage;
