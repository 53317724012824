import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Edit, CheckCircle, Cancel, ReportProblem } from "@mui/icons-material";
import "../styles/Profile.css";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("access_token");
      if (token) {
        try {
          const response = await apiClient.get(endpoints.users.profile, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUser(response.data);
        } catch (error) {
          console.error("Lỗi khi lấy thông tin người dùng:", error);
          localStorage.removeItem("access_token");
          navigate("/home");
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/home");
      }
    };

    fetchUserProfile();
  }, [navigate]);

  const renderKycStatus = (status) => {
    switch (status) {
      case "verified":
        return (
          <Box className="kyc-status verified">
            <CheckCircle className="kyc-icon" />
            <Typography variant="body2" className="kyc-text">
              Đã xác minh
            </Typography>
          </Box>
        );
      case "pending":
        return (
          <Box className="kyc-status pending">
            <ReportProblem className="kyc-icon" />
            <Typography variant="body2" className="kyc-text">
              Đang chờ xác minh
            </Typography>
          </Box>
        );
      default:
        return (
          <Box className="kyc-status none">
            <Cancel className="kyc-icon" />
            <Typography variant="body2" className="kyc-text">
              Chưa xác minh
            </Typography>
          </Box>
        );
    }
  };

  if (loading) {
    return (
      <Box
        className="profile-page"
        sx={{ textAlign: "center", marginTop: "50px" }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box className="profile-page">
      {/* Header */}
      <Box className="profile-header">
        <Avatar
          className="profile-avatar"
          src={user?.avatar || "https://via.placeholder.com/150"}
          alt="Avatar"
        />
        <Typography variant="h5" className="profile-name">
          {user?.name || "Không rõ"}
        </Typography>

        {/* KYC Status */}
        {renderKycStatus(user?.kycStatus || "none")}

        <Typography className="profile-info">
          Cấp thành viên: <strong>{user?.personal_title || "Không có"}</strong>
        </Typography>
        <Typography className="profile-info">
          Bài đã đăng: {user?.posts_count || 0} | Lượt like:{" "}
          {user?.likes_count || 0} | Lượt theo dõi: {user?.followers_count || 0}
        </Typography>
        <Button
          variant="contained"
          className="new-post-btn"
          startIcon={<Edit />}
        >
          Đăng Bài Viết Ngay
        </Button>
        <Button
          variant="outlined"
          className="logout-btn"
          onClick={() => {
            localStorage.removeItem("access_token");
            navigate("/home");
          }}
        >
          Đăng xuất
        </Button>
      </Box>

      <Divider className="profile-divider" />

      {/* Tabs */}
      <Box className="tabs-wrapper">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          className="profile-tabs"
        >
          <Tab label="Tường nhà" />
          <Tab label="Nhiệm vụ của bạn" />
          <Tab label="Phân tích" />
        </Tabs>
      </Box>

      <Divider className="profile-divider" />

      {/* Tab Content */}
      <Box className="tab-content">
        {activeTab === 0 && (
          <Box className="tab-panel">
            <Typography variant="h6">Tường nhà</Typography>
            <Typography>
              Hiển thị các bài viết, hoạt động trên tường nhà của bạn.
            </Typography>
          </Box>
        )}
        {activeTab === 1 && (
          <Box className="tab-panel">
            <Typography variant="h6">Nhiệm vụ của bạn</Typography>
            <Typography>
              Hiển thị các nhiệm vụ và phần thưởng bạn đã hoàn thành.
            </Typography>
          </Box>
        )}
        {activeTab === 2 && (
          <Box className="tab-panel">
            <Typography variant="h6">Phân tích</Typography>
            <Typography>
              Hiển thị thống kê và phân tích hoạt động của bạn.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
